<template>
  <dashboard-layout>
    <loader-animation v-if="isLoading" />
    <div class="w-full p-5" v-if="!isLoading">
      <div class="flex flex-col">
        <div class="p-4 flex">
          <h1 class="text-3xl">
            Cursos
          </h1>
        </div>
        <v-client-table
          ref="table"
          :data="courses"
          :columns="columns"
          :options="options"
        >
          <div class="flex" slot="actions" slot-scope="{ row }">
            <router-link
              class="flex p-1 border-2 border-green-1002 w-max rounded-lg mr-4"
              :to="{ name: 'edit-course', params: { id: row.id } }"
              v-tooltip="{ content: 'Editar', placement: 'left' }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M12.9 6.858l4.242 4.243L7.242 21H3v-4.243l9.9-9.9zm1.414-1.414l2.121-2.122a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414l-2.122 2.121-4.242-4.242z"
                  fill="rgba(0,122,111,1)"
                />
              </svg>
            </router-link>
          </div>
        </v-client-table>
      </div>
    </div>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '../../../layouts/DashboardLayout';
import axios from '@/utils/axios';
import LoaderAnimation from '../../../components/LoaderAnimation.vue';

export default {
  name: 'Index',

  components: {
    DashboardLayout,
    LoaderAnimation
  },

  data() {
    return {
      isLoading: false,
      filter: {
        name: '',
        hasKeywords: ''
      },
      courses: [],
      columns: [
        'id',
        'name',
        'programming_nature',
        'programming_type',
        'occupational_area',
        'actions'
      ],
      options: {
        sortable: true,
        perPage: 10,
        perPageValues: [10, 15, 20, 25, 50],
        headings: {
          name: 'Nome',
          programming_nature: 'Natureza da Programação',
          programming_type: 'Tipo da Programação',
          occupational_area: 'Área Ocupacional',
          actions: 'Ações'
        },
        hiddenColumns: ['id'],
        sortIcon: {
          is: 'glyphicon-sort',
          base: 'glyphicon',
          up: 'glyphicon-chevron-up',
          down: 'glyphicon-chevron-down'
        },
        texts: {
          first: 'Primeiro',
          last: 'Último',
          filter: 'Filtrar:',
          filterBy: 'Filtrar por {column}',
          filterPlaceholder: 'Pesquise aqui...',
          count: '{from} - {to} de {count} cursos|{count} cursos|Um curso',
          limit: 'Registros por página:',
          page: 'Página:',
          noResults: 'Nenhum curso encontrado.',
          columns: 'Colunas'
        },
        pagination: {
          chunk: 5
        }
      }
    };
  },

  methods: {
    async fetchCourses() {
      this.isLoading = true;
      await axios.get('/api/cursos').then(({ data }) => {
        data.data.forEach(course => this.courses.push(course));
      });
      this.isLoading = false;
    }
  },

  created: function() {
    this.fetchCourses();
  }
};
</script>

<style scoped></style>
